import { FeatureSection } from "@akte-mord/common";
import { Grid, Typography, useTheme } from "@mui/material";
import { TypoEffectGerechtigkeit } from "./typo-effect-gerechtigkeit";
import BackgroundImage from "../../public/homepage/feature-2-bg.jpg";
import React from "react";

export const FeatureTwo: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgPosition="top left"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        container
        direction={{ xs: "column", md: "row" }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h3" sx={{ pt: 0 }}>
            Indices cachés & cold cases
          </Typography>

          <Typography paragraph variant="body1">
            Ce jeu d’enquête se passe d’instructions ; vous n’avez qu’à vous lancer. Vous recevez le
            dossier d’enquête et votre première mission, puis c’est à vous de jouer.
          </Typography>

          <Typography paragraph variant="body1">
            Le but du jeu est de résoudre l’enquête, seul·e ou en équipe. Pour y arriver, vous devez
            examiner attentivement tous les documents, suivre les indices cachés et croiser les
            informations.
          </Typography>

          <Typography paragraph variant="body1">
            Vous pouvez vérifier la solution de chaque mission en ligne et obtenir jusqu’à trois indices
            par tour, dans le cas où vous seriez dans l’impasse. Chaque indice vous rapproche un peu plus
            de la solution.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: { xs: "100%", sm: "75%", md: "100%" } }}>
          <TypoEffectGerechtigkeit />
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
