import { Box, useTheme } from "@mui/material";
import { FeatureSection, Quote } from "@akte-mord/common";
import BackgroundImage from "../../public/homepage/quote-1-bg.jpg";
import React from "react";

export const QuoteOne: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="top right"
    >
      <Box sx={{ py: { xs: 2, sm: 3, md: 2 } }}>
        <Quote author="Tiré du dossier d’enquête de l’affaire Claire Lambert">
          {
            "Malgré une vaste enquête mobilisant d’importants moyens, il n’a jusque-là pas été possible d’exclure ni de confirmer avec certitude l’hypothèse d’un acte criminel."
          }
        </Quote>
      </Box>
    </FeatureSection>
  );
};
