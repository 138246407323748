import { FeatureFour } from "../homepage/feature-four";
import { FeatureOne } from "../homepage/feature-one";
import { FeatureThree } from "../homepage/feature-three";
import { FeatureTwo } from "../homepage/feature-two";
import { FollowUs, Footer } from "../footer";
import { GAMES } from "../data";
import { Header } from "../header";
import { Header as HeroHeader } from "../homepage/header";
import { NextSeo } from "next-seo";
import { QuoteOne } from "../homepage/quote-one";
import { QuoteTwo } from "../homepage/quote-two";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { Game } from "@akte-mord/common";
import type { GetStaticProps } from "next";

type Props = { featuredGame: { game: Game } };

const Home: React.FC<Props> = ({ featuredGame }) => (
  <React.Fragment>
    <NextSeo
      title="Le jeu d'enquête – COLD CASE® – Fouillez le passé. Menez l’enquête."
      description="Résolvez des affaires criminelles passionnantes avec ce jeu d’enquête regorgeant d’indices cachés. Classez l’affaire et démasquez le ou la coupable ! Jeu d’escape game avec plus de 59 éléments de preuve."
      openGraph={{
        type: "website",
        url: "https://www.cold-case.fr",
        title: "Le jeu d'enquête à la maison – COLD CASE – Jouez au détective !",
        description:
          "Résolvez des affaires criminelles passionnantes avec ce jeu d’enquête regorgeant d’indices cachés. Classez l’affaire et démasquez le ou la coupable !",
        images: [
          {
            url: "https://www.cold-case.fr/les-couleurs-de-l-oubli/produkt/og.jpg",
            width: 1200,
            height: 627,
            alt: "Le jeu d'enquête à la maison – COLD CASE – Jouez au détective !",
          },
        ],
      }}
    />

    <Header />
    <HeroHeader
      featuredGame={{
        image: featuredGame.game.image,
        productId: featuredGame.game.slug,
        slug: featuredGame.game.slug,
        title: featuredGame.game.title,
      }}
    />

    <main>
      <FeatureOne />
      <QuoteOne />
      <FeatureTwo />
      <FeatureThree />
      <QuoteTwo />
      <FeatureFour />
    </main>
    {/* <FollowUs /> */}
    <Footer light />
  </React.Fragment>
);

export default Home;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const featuredGame = GAMES[0];

  return {
    props: {
      ...(locale
        ? await serverSideTranslations(locale, ["c-common", "c-user-consent-management"], i18nConfig)
        : {}),
      featuredGame: {
        game: featuredGame,
      },
    },
  };
};
