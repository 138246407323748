import { FeatureSection, InlineLink, InternalInlineLink } from "@akte-mord/common";
import { Grid, Link, Typography, useTheme } from "@mui/material";
import { TypoEffectColdCase } from "./typo-effect-cold-case";
import BackgroundImage from "../../public/homepage/feature-3-bg.jpg";
import NextLink from "next/link";
import React from "react";

export const FeatureThree: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection bgColor={theme.palette.primary.main} bgImage={BackgroundImage} bgPosition="top left">
      <Link component={NextLink} href="/">Foo</Link>
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        container
        direction={{ xs: "column-reverse", md: "row" }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6} sx={{ width: { xs: "100%", sm: "75%", md: "100%" } }}>
          <TypoEffectColdCase />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" sx={{ pt: 0 }}>
            Enquêtez sur un meurtre
          </Typography>
          <Typography paragraph variant="body1">
            Nos jeux d’enquête à la maison sont conçus pour être joués seul ou en groupe, avec jusqu’à
            cinq personnes. Plus de 59 éléments de preuve et indices réalistes, dont plusieurs
            enregistrements audio d’interrogatoires, vous attendent pour résoudre une affaire criminelle
            passionnante.
          </Typography>
          <Typography paragraph variant="body1">
            Quelques conseils pour réussir votre enquête :
          </Typography>

          <ul className="list">
            <li>Lisez attentivement tous les documents relatifs à l’affaire.</li>
            <li>Prenez des notes sur les lieux du crime et les différents suspects.</li>
            <li>Assurez-vous que tous les joueurs et joueuses lisent l’ensemble des documents.</li>
            <li>
              Vérifiez votre solution sur notre{" "}

                <InternalInlineLink href="/solution">site internet</InternalInlineLink>

              .
            </li>
            <li>N’ouvrez l’enveloppe d’un tour qu’une fois le tour précédent résolu.</li>
            <li>
              Vous y trouverez aussi des indices dans le cas où vous n’arriveriez pas à passer au tour
              suivant.
            </li>
            <li>N’ouvrez la résolution qu’à la fin du jeu.</li>
            <li>
              • D’autres réponses à vos questions sont disponibles{" "}
              <InternalInlineLink href="/aide">ici</InternalInlineLink>
              .
            </li>
          </ul>
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
