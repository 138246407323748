import { Box, useTheme } from "@mui/material";
import { FeatureSection, Quote } from "@akte-mord/common";
import BackgroundImage from "../../public/homepage/quote-2-bg-fr.jpg";
import React from "react";

export const QuoteTwo: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgPosition="center right"
    >
      <Box sx={{ pt: 2 }}>
        <Quote author="Philippe Lambert, frère de la victime">
          Je ne comprends pas, pourquoi ma sœur est-elle morte ?
        </Quote>
      </Box>
    </FeatureSection>
  );
};
