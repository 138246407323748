import { MaskedImage } from "@akte-mord/common";
import BackgroundImage from "../../public/typo-effects/cold-case-typo-bg.jpg";

export const TypoEffectColdCase: React.FC = () => {
  const alt = "Enquêtez dans ce jeu de détective passionnant";
  const title = "";

  return (
    <MaskedImage
      alt={alt}
      id="cold-case-clip-path"
      path={
        <path d="M0.138,0.225 V0.193 C0.138,0.16,0.138,0.141,0.124,0.141 S0.11,0.16,0.11,0.193 V0.3 C0.11,0.334,0.11,0.352,0.124,0.352 S0.138,0.334,0.138,0.3 V0.265 H0.249 V0.272 C0.249,0.358,0.249,0.493,0.124,0.493 S-0.002,0.346,-0.002,0.26 V0.233 C-0.002,0.147,-0.002,0,0.124,0 S0.249,0.135,0.249,0.22 V0.225 H0.138 M0.264,0.214 V0.26 C0.264,0.346,0.264,0.493,0.389,0.493 S0.515,0.357,0.515,0.26 V0.233 C0.515,0.137,0.515,0,0.389,0 S0.264,0.128,0.264,0.214 M0.537,0.476 H0.73 V0.327 H0.649 V0.064 L0.649,0.017 H0.537 L0.537,0.064 V0.41 L0.537,0.476 M0.907,0.017 H0.746 L0.747,0.064 V0.429 L0.746,0.476 H0.907 C0.97,0.476,0.998,0.402,0.998,0.305 V0.188 C0.999,0.086,0.968,0.017,0.907,0.017 M0.249,0.772 H0.138 V0.807 C0.138,0.841,0.138,0.859,0.124,0.859 S0.11,0.841,0.11,0.807 V0.7 C0.11,0.666,0.11,0.648,0.124,0.648 S0.138,0.666,0.138,0.7 V0.732 H0.249 V0.727 C0.249,0.642,0.249,0.507,0.124,0.507 S-0.002,0.654,-0.002,0.74 V0.767 C-0.002,0.853,-0.002,1,0.124,1 S0.249,0.865,0.249,0.779 V0.772 M0.316,0.524 L0.243,0.983 H0.351 L0.358,0.926 H0.42 L0.427,0.983 H0.538 L0.465,0.524 H0.316 M0.698,0.688 L0.686,0.683 C0.663,0.672,0.64,0.665,0.64,0.641 C0.64,0.63,0.646,0.62,0.657,0.62 C0.67,0.62,0.679,0.632,0.679,0.659 V0.665 H0.774 V0.658 C0.774,0.555,0.726,0.507,0.653,0.507 C0.57,0.507,0.532,0.568,0.532,0.664 S0.568,0.782,0.612,0.804 L0.623,0.81 C0.647,0.822,0.67,0.835,0.67,0.863 C0.67,0.879,0.664,0.887,0.652,0.887 C0.632,0.887,0.625,0.863,0.625,0.831 V0.827 H0.531 V0.837 C0.531,0.954,0.584,1,0.661,1 C0.745,1,0.785,0.945,0.785,0.843 S0.742,0.709,0.698,0.688 M0.801,0.571 V0.936 L0.801,0.983 H0.994 V0.84 H0.913 V0.804 H0.986 V0.7 H0.913 V0.667 H0.994 V0.524 H0.801 L0.801,0.571" />
      }
      src={BackgroundImage}
      viewBox="0 0 1160 674.3"
      title={title}
    />
  );
};
