import { MaskedImage } from "@akte-mord/common";
import BackgroundImage from "../../public/typo-effects/justice-typo-bg.jpg";

export const TypoEffectGerechtigkeit: React.FC = () => {
  const alt = "Jeu de crime caché Jeu de scène de crime";
  const title = "";

  return (
    <MaskedImage
      alt={alt}
      id="gerechtigkeit-clip-path"
      path={
        <path d="M0.041,0.479 H0 V0.336 H0.028 C0.047,0.336,0.056,0.324,0.056,0.289 V0.064 L0.056,0.017 H0.191 L0.191,0.064 V0.316 C0.191,0.424,0.146,0.479,0.041,0.479 M0.219,0.323 C0.219,0.424,0.269,0.493,0.371,0.493 S0.518,0.424,0.518,0.323 V0.064 L0.518,0.017 H0.387 L0.387,0.064 V0.293 C0.387,0.319,0.383,0.333,0.371,0.333 S0.354,0.32,0.354,0.293 V0.064 L0.355,0.017 H0.219 L0.219,0.064 V0.323 M0.693,0.493 C0.794,0.493,0.842,0.439,0.842,0.337 S0.791,0.202,0.737,0.182 L0.724,0.176 C0.695,0.166,0.668,0.158,0.668,0.135 C0.668,0.123,0.675,0.113,0.689,0.113 C0.704,0.113,0.715,0.126,0.715,0.152 V0.158 H0.829 V0.151 C0.829,0.049,0.772,0,0.683,0 C0.583,0,0.537,0.062,0.537,0.157 S0.58,0.275,0.634,0.298 L0.648,0.303 C0.676,0.316,0.704,0.328,0.704,0.357 C0.704,0.372,0.696,0.38,0.682,0.38 C0.658,0.38,0.649,0.357,0.649,0.324 V0.32 H0.536 V0.33 C0.536,0.447,0.6,0.493,0.693,0.493 M0,0.673 H0.063 V0.936 L0.062,0.983 H0.198 L0.197,0.936 V0.673 H0.26 V0.524 H0 V0.673 M0.416,0.524 H0.281 L0.281,0.571 V0.936 L0.281,0.983 H0.416 L0.416,0.936 V0.571 L0.416,0.524 M0.593,0.507 C0.442,0.507,0.442,0.654,0.442,0.74 V0.767 C0.442,0.853,0.442,1,0.593,1 S0.744,0.865,0.744,0.779 V0.772 H0.61 V0.807 C0.61,0.841,0.61,0.859,0.593,0.859 S0.576,0.841,0.576,0.807 V0.7 C0.576,0.666,0.576,0.648,0.593,0.648 S0.61,0.666,0.61,0.7 V0.732 H0.744 V0.727 C0.744,0.642,0.744,0.507,0.593,0.507 M1,0.667 V0.524 H0.767 L0.768,0.571 V0.936 L0.767,0.983 H1 V0.84 H0.903 V0.804 H0.99 V0.7 H0.903 V0.667 H1" />
      }
      src={BackgroundImage}
      viewBox="0 0 1160 811.71"
      title={title}
    />
  );
};
