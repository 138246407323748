import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { HeroHeader, LocalImage, ProductImage } from "@akte-mord/common";
import BackgroundImage from "../../public/homepage/hero-header-bg.jpg";
import Link from "next/link";
import React from "react";

type Props = {
  featuredGame: { image: LocalImage; title: string; slug: string; productId: string };
};

type Channel = { label: string; url: string };

export const Header: React.FC<Props> = ({ featuredGame }) => {
  const theme = useTheme();

  const channels: Channel[] = [
    {
      label: "Acheter sur Amazon",
      url: "https://www.amazon.fr/Blackrock-Games-Cold-couleurs-loubli/dp/B07VPV3B1Q/",
    },
    {
      label: "Acheter sur Cultura",
      url: "https://www.cultura.com/p-cold-case-les-couleurs-de-l-oubli-jeu-d-enquete-immersif-10149959.html",
    },
    {
      label: "Acheter prés de chez vous",
      url: "https://blackrockgames.fr/boutiques",
    },
  ];

  return (
    <HeroHeader
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="center center"
    >
      <Stack
        alignItems={{ xs: "center", md: "flex-start" }}
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
        spacing={{ xs: 6, sm: 8, md: 12, xl: 16 }}
      >
        <Box sx={{ flexGrow: 1, pt: { xs: 0, md: 8 }, pb: { xs: 2, sm: 3, md: 0 } }}>
          <Typography sx={{ hyphens: "none", textAlign: { xs: "center", md: "left" } }} variant="h1">
            Fouillez le passé. <br />
            Menez l’enquête.
          </Typography>
          <Typography sx={{ textAlign: { xs: "center", md: "left" } }} variant="body2" paragraph>
            Éléments de preuve, indices cachés, interrogatoires ! Entre escape game, jeu d’enquête et
            polar, vous devez résoudre des affaires de meurtres classées sans suite. Pas d’impunité pour
            les meurtriers. Menez l’enquête !
          </Typography>
          <Typography sx={{ textAlign: { xs: "center", md: "left" } }} variant="body2" paragraph>
            Pour 24,00 € TVA incluse.
          </Typography>

          <Stack
            direction="column"
            justifyContent={{ sm: "center", md: "flex-start" }}
            spacing={2}
            sx={{ pt: 3 }}
          >
            {/* <Link href={`https://blackrockgames.fr/boutiques`} passHref>
              <Button color="secondary" variant="outlined">
                Où l’acheter
              </Button>
            </Link> */}
            <Link href={`/jeux/${featuredGame.slug}`} passHref>
              <Button color="secondary" variant="contained" sx={{ width: "100%" }}>
                À propos de l’enquête
              </Button>
            </Link>
            {channels.map(({ label, url }) => (
              <Button
                key={`channel-${label}`}
                data-testid="go-to-cart-btn"
                endIcon={<ChevronRight />}
                href={url}
                size="small"
                color="secondary"
                variant="outlined"
                // sx={{
                //   color: theme.palette.primary.dark,
                //   "&.MuiButton-root": {
                //     backgroundColor: theme.palette.primary.dark,
                //     color: "white",
                //   },
                //   // mt: { xs: 4, md: 3 },
                //   width: { xs: "100%", sm: "unset", mx: { xs: "0 auto", md: "0" } },
                // }}
              >
                {label}
              </Button>
            ))}
          </Stack>
        </Box>
        <ProductImage link={`/jeux/${featuredGame.slug}`} {...featuredGame.image} />
      </Stack>
    </HeroHeader>
  );
};
