import { FeatureSection, InlineLink } from "@akte-mord/common";
import { Grid, Typography, useTheme } from "@mui/material";
import { TypoEffectLeidenschaft } from "./typo-effect-leidenschaft";
import BackgroundImage from "../../public/homepage/feature-4-bg.jpg";
import React from "react";

export const FeatureFour: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="center right"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        container
        direction={{ xs: "column", md: "row" }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h3" sx={{ pt: 0 }}>
            Conçu par des experts des jeux d’enquête
          </Typography>

          <Typography paragraph variant="body1">
            Derrière COLD CASE® se cachent les créateurs de{" "}
            <InlineLink
              href="https://www.culinario-mortale.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Culinario Mortale
            </InlineLink>
            , l’une des meilleures collections de dîners-enquêtes.
          </Typography>

          <Typography paragraph variant="body1">
            Nos jeux d’enquête s’adressent aux fans de polars, d’énigmes et d’escape game. Nous venons du
            milieu des médias, du cinéma et du théâtre. Raconter des histoires passionnantes de façon
            réaliste est un métier que nous avons appris. En plus de nos recherches approfondies, nous
            avons contacté des avocats et des enquêteurs au cours de la création de nos jeux afin de
            concevoir des affaires criminelles aussi réalistes que captivantes.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: { xs: "100%", sm: "75%", md: "100%" } }}>
          <TypoEffectLeidenschaft />
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
