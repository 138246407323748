import { MaskedImage } from "@akte-mord/common";
import BackgroundImage from "../../public/typo-effects/passion-typo-bg.jpg";

export const TypoEffectLeidenschaft: React.FC = () => {
  const alt = "Par les créateurs de dîners mystères de Culinario Mortale";
  const title = "Tu trouveras nos dîners enquêtes sur www.culinario-mortale.fr";

  return (
    <MaskedImage
      alt={alt}
      id="leidenschaft-clip-path"
      path={
        <path d="M0.289,0.18 C0.289,0.294,0.242,0.335,0.183,0.335 H0.133 V0.429 L0.134,0.476 H0.011 L0.011,0.429 V0.064 L0.011,0.017 H0.183 C0.242,0.017,0.289,0.056,0.289,0.169 V0.18 M0.588,0.476 H0.466 L0.459,0.419 H0.391 L0.384,0.476 H0.267 L0.346,0.017 H0.508 L0.588,0.476 M0.682,0.32 V0.324 C0.682,0.357,0.69,0.38,0.712,0.38 C0.725,0.38,0.732,0.372,0.732,0.357 C0.732,0.328,0.707,0.316,0.681,0.303 L0.668,0.298 C0.62,0.275,0.581,0.25,0.581,0.157 S0.623,0,0.713,0 C0.793,0,0.845,0.049,0.845,0.151 V0.158 H0.741 V0.152 C0.741,0.126,0.731,0.113,0.718,0.113 C0.705,0.113,0.699,0.123,0.699,0.135 C0.699,0.158,0.723,0.166,0.749,0.176 L0.762,0.182 C0.81,0.202,0.857,0.232,0.857,0.337 S0.813,0.493,0.722,0.493 C0.637,0.493,0.579,0.447,0.579,0.33 V0.32 H0.682 M0.103,0.827 V0.831 C0.103,0.863,0.111,0.887,0.132,0.887 C0.145,0.887,0.152,0.879,0.152,0.863 C0.152,0.834,0.127,0.822,0.101,0.81 L0.089,0.804 C0.04,0.782,0.001,0.756,0.001,0.664 S0.043,0.507,0.133,0.507 C0.213,0.507,0.265,0.555,0.265,0.658 V0.665 H0.162 V0.659 C0.162,0.632,0.152,0.62,0.138,0.62 C0.126,0.62,0.119,0.63,0.119,0.641 C0.119,0.665,0.144,0.672,0.17,0.683 L0.182,0.688 C0.231,0.709,0.278,0.738,0.278,0.843 S0.234,1,0.142,1 C0.058,1,0,0.954,0,0.837 V0.827 H0.103 M0.417,0.524 L0.417,0.571 V0.936 L0.417,0.983 H0.295 L0.295,0.936 V0.571 L0.295,0.524 H0.417 M0.578,1 C0.441,1,0.441,0.853,0.441,0.767 V0.721 C0.441,0.634,0.441,0.507,0.578,0.507 S0.715,0.643,0.715,0.74 V0.767 C0.715,0.864,0.715,1,0.578,1 M0.739,0.936 V0.571 L0.738,0.524 H0.842 L0.889,0.666 H0.892 V0.603 L0.891,0.524 H1 L1,0.571 V0.936 L1,0.983 H0.896 L0.848,0.842 H0.846 V0.904 L0.846,0.983 H0.738 L0.739,0.936" />
      }
      src={BackgroundImage}
      viewBox="0 0 1160 735"
      title={title}
    />
  );
};
