import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FeatureSection, YoutubeEmbed } from "@akte-mord/common";
import { TypoEffectMord } from "./typo-effect-mord";
import BackgroundImage from "../../public/homepage/feature-1-bg.jpg";
import React from "react";
import YotubePoster from "../../public/homepage/cold-case-youtube-preview.jpg";

export const FeatureOne: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgPosition="top center"
    >
      <Box sx={{ mb: { xs: 6, md: 16 } }}>
        <YoutubeEmbed
          aspectRatio="2.258 / 1"
          id="XRcwDboPC0c"
          posterImage={YotubePoster}
          title="Detektivspiele für Erwachsene - Such den Mörder"
        />
      </Box>
      <Grid
        alignItems="center"
        container
        direction={{ xs: "column-reverse", md: "row" }}
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6} sx={{ width: { xs: "100%", sm: "75%", md: "100%" } }}>
          <TypoEffectMord />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h3" sx={{ pt: 0 }}>
            Jeu d’enquête et escape game
          </Typography>

          <Typography paragraph variant="body1">
            En tant que membres d’une unité spéciale, vous enquêtez sur des affaires de meurtres non
            résolues. Vous devez pour cela vous plonger dans les dossiers, consulter les documents à
            votre disposition, examiner de vieilles coupures de journaux et écouter les enregistrements
            d’interrogatoires réalisés à l’époque. Vous avez le droit de recourir à tous les outils à
            votre disposition pour résoudre l’affaire.
          </Typography>

          <Typography paragraph variant="body1">
            Trois missions vous attendent pour débusquer le ou la coupable. Après toutes ces années, vous
            seul·e·s pouvez vous assurer que justice soit rendue pour les victimes.
          </Typography>
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
